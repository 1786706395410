import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Container from '../../components/Container';

export default function PrivacyPage(props) {
  const { html } = props.data.markdownRemark;
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PrivacyPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/markdown/privacy.md/" }) {
      html
    }
  }
`;
